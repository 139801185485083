import React from "react"
import { Button, Grid, Paper, TextField, FontIcon, Snackbar } from "react-md";
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image";
import { get } from 'https';
const fetch = require('node-fetch');
import { ReCaptcha } from 'react-recaptcha-v3'
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import Conditional from '../Conditional';

const SUBMIT_URL = "/api/subscribe";
const { GATSBY_RECAPTCHA_SITE_KEY, GATSBY_ENV } = process.env;

class DownloadCard extends React.Component {

    constructor(props) {
      super(props);
      this.state = {
        email: "",
        token: "",
        buttonDisabled: false,
        buttonText: "Send it!"
      };

      this.handleChange = this.handleChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(value, event) {
      console.log(event);
      this.setState({email: value});
    }

    reCaptchaVerifyCallback = (recaptchaToken) => {
      this.setState({token: recaptchaToken});
    }

    async handleSubmit(event) {
      event.preventDefault();
      this.setState({buttonText: 'On its way!'});
      this.setState({buttonDisabled: true});
      trackCustomEvent({
        category: "Lead Magnet",
        action: "button-click",
        label: "Lean On Me Bonus"
      });
      const response = await fetch('/api/subscribe/', {
        method: "POST",
        body: JSON.stringify({
          email: this.state.email,
          tag: "Lean On Me Bonus",
          token: this.state.token
        })
      });
      const resData = await response.json();
      if (resData && resData.statusCode && resData.statusCode === 200) {
        trackCustomEvent({
          category: "Lead Magnet",
          action: "subscribe-response-success",
          label: "Lean On Me Bonus"
        });
      } else {
        trackCustomEvent({
          category: "Error",
          action: "subscribe-response-error",
          label: "Lean On Me Bonus"
        });
      }
    }

    render() {
        const { href, text, title, subtitle } = this.props;
        return (
            <StaticQuery
              query={graphql`
                query {
                    file(relativePath: { eq: "lean_on_me_bonus.png" }) {
                        childImageSharp {
                            fluid(fit: CONTAIN) {
                                ...GatsbyImageSharpFluid

                            }
                        }
                    }
                }
              `}
              render={data => (
                <Paper zDepth={1} style={{marginBottom:"1rem"}}>
                    <Grid>
                        <div style={{textAlign:"center"}} className="md-cell md-divider-border md-divider-border--right">
                            <Img fluid={data.file.childImageSharp.fluid}></Img>
                        </div>
                        <div className="md-cell md-cell--8 md-cell--4-tablet md-cell--4-phone"
                          style={{alignSelf:"center"}}>
                            <h3 style={{whiteSpace:"pre-wrap"}}>{title}</h3>
                            <h4>{subtitle}</h4>
                            <form onSubmit={this.handleSubmit}>
                              <TextField
                                id="email-address"
                                label="Your Best Email Address"
                                type="email"
                                disabled={this.state.buttonDisabled}
                                leftIcon={<FontIcon>email</FontIcon>}
                                size={14}
                                fullWidth={true}
                                value={this.state.email}
                                onChange={this.handleChange}
                              />
                              <Button
                                primary
                                raised
                                disabled={this.state.buttonDisabled}
                                type="submit"
                                style={{width:"100%", marginTop:"0.5rem"}}
                              >
                              {this.state.buttonText}
                              </Button>
                              <Conditional if={GATSBY_ENV === "prod"}>
                                <ReCaptcha
                                  sitekey={GATSBY_RECAPTCHA_SITE_KEY}
                                  action='lean_on_me_bonus'
                                  verifyCallback={this.reCaptchaVerifyCallback}
                                />
                              </Conditional>
                            </form>
                        </div>
                    </Grid>
                </Paper>
              )}
            />
          );
    }
}

export default DownloadCard;
